import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { collect } from '@temsa/helpers/utils';

interface ISeatsLayoutProps {
  trans: (key: string) => string;
  layouts: string[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: e.target.value, name: e.target.value, filter: 'seats_layout'}]);
};

export default React.memo(function SeatsLayout({layouts, parameters, handler, trans}: ISeatsLayoutProps) {
  const selected = parameters.seats_layout ? (collect(parameters.seats_layout).first() || '').replace(' ', '+') : '';
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('seats-layout')}</h6>
      <div className="form-group" >
        <select value={selected || ''} onChange={(e) => prepare({...e}, handler)} name="seats_layout" className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {layouts.map((layout, i) => <option key={i} value={layout}>{layout}</option>)}
        </select>
      </div>
    </div>
  );
});