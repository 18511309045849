import * as React from "react";
import SeatsRange from "./SeatsRange";
import { IKeyValueObject } from "@temsa/interfaces/generic";
import * as filterSetters from "resources/assets/js/helpers/filter";
import { closeAllDropdownInFilter } from "@temsa/helpers/jquery";
import TopFiltersHoc from "../TopFiltersHoc";
export interface INumberOfSeatsProps {
  trans: (key: string) => string;
  add: (params: IKeyValueObject, tags: IKeyValueObject) => any;
  handler: () => any;
  clear: (cb: CallableFunction) => any;
  state: IKeyValueObject;
  min: string;
  max: string;
  limit: IKeyValueObject;
}

function NumberOfSeats(params: INumberOfSeatsProps) {
  const minSeats = !Array.isArray(params.state.params.seats_min) ? (params.state.params.seats_min || '') : params.state.params.seats_min[0];
  const maxSeats = !Array.isArray(params.state.params.seats_max) ? (params.state.params.seats_max || '') : params.state.params.seats_max[0];
  return (
    <div className="seats-ranges-dropdown-wrapper">
      <div className="dropdown filter-dropdown">
        <button
          className="btn btn-outline-dark dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          {params.trans('number-of-seats')}{" "}
          <svg
            className="icon icon-angle-down"
            dangerouslySetInnerHTML={{
              __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-angle-down"></use>'
            }}
          />
        </button>
        <div className="dropdown-menu">
          <form id="filter_seats_params">
            <SeatsRange 
              trans={params.trans} 
              index={0} 
              min={minSeats} 
              max={maxSeats} 
              limit={params.limit} 
              onChange={params.handler} 
            />
            <div className="filter-bottom">
              <button 
                type="button"  
                className="btn btn-link"
                onClick={() => params.clear(() => params.add({seats_min: [], seats_max: []}, []))} 
                style={{visibility: (filterSetters.isValidMinMax(minSeats, maxSeats)) ? 'visible' : 'hidden'}}>
                {params.trans('clear')}
              </button>
              <button 
                type="button" 
                className="btn btn-link"
                disabled={!filterSetters.isValidMinMax(minSeats, maxSeats)}
                onClick={() => closeAllDropdownInFilter()}>
                {params.trans('apply')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TopFiltersHoc(
  // Stateless component.
  // it will wrap from hoc component.
  NumberOfSeats, 

  // range inputs change handler.
  filterSetters.rangeChangeHandler(), 

  '', 

  // filter parameter name.
  'seats-ranges',

  // dropdown close event handler.
  filterSetters.rangeCloseEventHandler('seats_min', 'seats_max', 'seats-ranges', {
    name: (min: string, max: string) => {
      return min+' - '+max;
    }
  }),

  filterSetters.equalityStrategy('seats_min', 'seats_min')
));