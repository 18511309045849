import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';

interface IRegistrationDateProps {
  trans: (key: string) => string;
  min: number;
  max: number;
  range: IKeyValueObject;
  onChange: (e: any, selector: string) => any;
}

export default React.memo(function RegistrationDate({min, max, onChange, trans, range}: IRegistrationDateProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('registration-date')}</h6>
      <div className="form-row">
        <div className="col-md-6">
          <input 
            type="string" 
            value={min} 
            className="form-control registration_date_min" 
            maxLength={4}
            name="registration_date_min"
            onChange={(e: any) => onChange(({...e}).target.value, '.registration_date_min')}
            placeholder={range.registration_date_min}
          />
        </div>
        <div className="col-md-6">
          <input 
            type="string" 
            value={max} 
            className="form-control registration_date_max" 
            name="registration_date_max"
            maxLength={4}
            onChange={(e: any) => onChange(({...e}).target.value, '.registration_date_max')}
            placeholder={range.registration_date_max}
          />
        </div>
      </div>
    </div>
  );
});