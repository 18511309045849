import { win } from "./globals";

export function closeFilterDropdown() {
  // win('$')('.filter-dropdown .dropdown-menu').dropdown('hide');
}

export function onShownFilterDropdown(cb: CallableFunction) {
  win('$')('#filters').on('show.bs.dropdown', cb);
}

export function onHideFilterDropdown(cb: CallableFunction) {
  win('$')('#filters').on('hide.bs.dropdown', cb);
}

export function closeAllDropdownInFilter() {
  win('$')('#dropdown-background-overlay').click();
}