import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import ArrayCollection from 'resources/assets/js/extensions/ArrayCollection';
import { positiveInt } from '@temsa/helpers/utils';

interface IYearOfManufactureProps {
  trans: (key: string) => string;
  years: number[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: 'year', name: e.target.value, filter: 'year'}]);
};

export default React.memo(function YearOfManufacture({years, parameters, handler, trans}: IYearOfManufactureProps) {
  const collection = new ArrayCollection(years.sort());
  const length = positiveInt(collection.last() - collection.first());
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('year-of-manufacture')}</h6>
      <div className="form-group" >
        <select name="year" value={parameters.year || ''} onChange={(e) => prepare({...e}, handler)} className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {(new Array(length)).fill(1).map((i: number, index: number) => <option key={collection.first() + (index + 1)} value={collection.first() + (index + 1)}>{collection.first() + (index + 1)}</option>)}
        </select>
      </div>
    </div>
  );
});
