import * as React from 'react';
import { formatMoney, IFormatMoneyOptions } from 'accounting-js';
import FavoriteButton from "../FavoriteButton";
import { showToast } from '../../actions/toast';
import { connect } from 'react-redux';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { win } from '@temsa/globals';
import { kebabCase, config, get } from '@temsa/utils';
import envService from '../../services/envService';
import ArrayCollection from 'resources/assets/js/extensions/ArrayCollection';
import { addItemToFavoritesAction, removeFavoriteAction } from '@temsa/react/actions/favorites';
import CompareButton from '../CompareButton';
import Favorite from '@temsa/react/models/Favorite';
import { compose } from 'redux';
import TranslationHoc from '@temsa/react/hocs/TranslationHoc';
import CardActionHoc from '@temsa/react/hocs/CardActionHoc';
import { addItemToComparisionAction, removeComparisionAction } from '@temsa/react/actions/comparision';
import { close, open } from '@temsa/react/actions/modal';

export interface IListCardProps {
  addItem: (item: IKeyValueObject) => any;
  removeItem: (region: string, id: string) => any;
  toast: (data: IKeyValueObject) => any;
  favorites: IKeyValueObject[];
  item: IKeyValueObject;
  trans: (key: string) => string;
}

class ListCard extends React.Component<IListCardProps | IKeyValueObject, IKeyValueObject> {
  private lookup = (key: string, defualtValue?: any) => win('_lookupTable')[key] || defualtValue;
  constructor(props: IListCardProps | IKeyValueObject) {
    super(props);
  }

  public componentDidCatch(error: any, errorInfo: any) {
    console.log('componentDidCatch', error, errorInfo);
  }

  public render() {
    const partials = [
      this.props.item.year,
      this.lookup(this.props.item.brand, '').toLowerCase(),
      this.lookup(this.props.item.model)
    ];

    const route = win('_listing');
    const code = win('_language') + '-' + win('_region');
    const shortId = this.props.item.short_id.toUpperCase();
    const slug = kebabCase(partials.join(' ')) + '-' + shortId;
    const url = [envService.get('api.baseUrl'), code, route, slug].join('/');
    const currency = this.props.item.currency;
    const symbol: string = config(`moneySymbols.${currency}`);
    const formats: string[] = config('moneyFormats')[currency];
    const moneyFormat = (new ArrayCollection(formats)).toObject(['thousand', 'decimal', 'format']);
    const price = this.props.item.price;

    console.log('FORMAT PRICE ==>', price);

    const formatPrice = formatMoney(
      this.props.item.price,
      {
        symbol,
        precision: 0,
        ...moneyFormat
      } as IFormatMoneyOptions
    );

    return (
      <div className="col-6 col-lg-4" key={this.props.item._id}>
        <div className="card card-second-hand-thumbnail">
          <div className="item-actions" >
            <span
              data-url={url}
              data-price={formatPrice}
              data-id={this.props.item._id}
              data-image={this.props.item.media[0]}
              data-short-id={this.props.item.short_id}
              data-title={`${this.props.item.year} ${this.lookup(this.props.item.brand)} ${this.lookup(this.props.item.model)}`}
              className="comparision-action">
              <CompareButton  {...this.props as any} />
            </span>
            <span
              data-url={url}
              data-price={formatPrice}
              data-id={this.props.item._id}
              data-image={this.props.item.media[0]}
              data-short-id={this.props.item.short_id}
              data-title={`${this.props.item.year} ${this.lookup(this.props.item.brand)} ${this.lookup(this.props.item.model)}`}
              className="favorite-action">
              <FavoriteButton  {...this.props as any} />
            </span>
          </div>
          <div id={`secondHandCarousel-${this.props.item._id}`} className="carousel slide" data-ride="carousel" data-interval="false">
            <a href={url} className="carousel-inner">
              {this.props.item.media.map((imgUrl: string, index: number) => {
                return (
                  <div key={index} className={"carousel-item" + (index === 0 ? ' active' : '')}>
                    <img src={imgUrl} alt="" />
                  </div>
                );
              })}
            </a>
            {this.props.item.media.length > 1 &&
              <>
                <a className="carousel-control-prev" href={`#secondHandCarousel-${this.props.item._id}`} role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true">
                    <svg className="icon icon-arrow-right" dangerouslySetInnerHTML={{ __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-arrow-left"></use>' }} />
                  </span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href={`#secondHandCarousel-${this.props.item._id}`} role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true">
                    <svg className="icon icon-arrow-right" dangerouslySetInnerHTML={{ __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-arrow-right"></use>' }} />
                  </span>
                  <span className="sr-only">Next</span>
                </a>
              </>
            }
          </div>
          <div className="card-body">
            <h5 className="card-title">{this.props.item.year} {this.lookup(this.props.item.brand)} {this.lookup(this.props.item.model)}</h5>
            <p className="card-text">
              {this.props.item.mileage} {this.props.item.mileage_type} {' - '}
              {this.props.item.euro_norm ? this.lookup(this.props.item.euro_norm) : ''} {this.props.item.euro_norm ? ' - ' : ''}
              {this.props.item.number_of_seats} {this.props.trans('seats')} <br />
              {this.props.item.engine_power} {this.props.trans('engine-power-hp')} {' - '}
              {this.lookup(this.props.item.gearing_type)} - {this.lookup(this.props.item.fuel_type)}
            </p>
            {price > 0 && <h5 style={{ minHeight: '32px' }} className="card-second-hand-price">{formatPrice}</h5>}
            {price === 0 && <p className="card-text">{this.props.trans('contact-for-price')}</p>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IKeyValueObject) => {
  return {
    favorites: state.favorites || [],
    comparisions: state.comparisions || {},
    translations: state.translations.data
  };
};

const mapDispatchToProps = (dispatch: CallableFunction) => {
  return {
    addItemToFavorite: (items: IKeyValueObject[]) => dispatch(addItemToFavoritesAction(items)),
    removeItemFromFavorite: (region: string, id: string) => dispatch(removeFavoriteAction(region, id)),
    addItemToCompare: (items: IKeyValueObject[]) => dispatch(addItemToComparisionAction(items)),
    removeItemFromCompare: (region: string, id: string) => dispatch(removeComparisionAction(region, id)),
    addItem: (items: IKeyValueObject[]) => dispatch(addItemToFavoritesAction(items)),
    removeItem: (region: string, id: string) => dispatch(removeFavoriteAction(region, id)),
    toast: (data: IKeyValueObject) => dispatch(showToast(data)),
    modal: (data: IKeyValueObject) => data.open ? dispatch(open(data)) : dispatch(close(data)),
  }
};

const composedHocs = compose(TranslationHoc, CardActionHoc)(ListCard);
const Component = connect(mapStateToProps, mapDispatchToProps)(composedHocs);

export default Component;