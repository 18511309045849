import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { setFormat } from '../../../../helpers/filter';

export interface ISeatsRangeProps {
  trans: (key: string) => string;
  onChange: (e: any, selector: string) => any;
  min: number;
  max: number;
  index: number;
  limit: IKeyValueObject;
}

export default React.memo(function SeatsRange({min, max, onChange, trans, index = 0, limit}: ISeatsRangeProps) {
  return (
    <div className="form-row">
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            maxLength={3}
            name="seats_min"
            min={limit.seats_min}
            value={min ? setFormat(min) : ''}
            className="form-control seats_min"
            placeholder={setFormat(limit.seats_min)}
            onChange={(e: any) => onChange(e, '.seats_min')}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            value={max}
            maxLength={3}
            name="seats_max"
            max={limit.seats_max}
            className="form-control seats_max"
            placeholder={setFormat(limit.seats_max)}
            onChange={(e) => onChange(e, '.seats_max')}
          />
        </div>
      </div>
    </div>
  );
});