import { REMOVE_QUERY_PARAMETERS_ACTION, ADD_QUERY_PARAMETERS_ACTION, ADD_TAG_PARAMETERS_ACTION, REMOVE_TAG_PARAMETERS_ACTION, ADD_SIDEBAR_PARAMETERS_ACTION, REPLACE_ALL_QUERY_PARAMETERS_ACTION, REPLACE_ALL_TAG_PARAMETERS_ACTION } from './types';
import { IKeyValueObject, IAction } from '@temsa/interfaces/generic';

export interface IAddQueryAction extends IAction {
  parameters: IKeyValueObject;
}

export interface IRemoveQueryAction extends IAction {
  id: string;
}

/**
 * Query parameters
 * @param parameters IKeyValueObject
 */
export function addQueryParamsAction(parameters: IKeyValueObject) {
  return {
    type: ADD_QUERY_PARAMETERS_ACTION,
    parameters
  }
}

export function removeQueryParamsAction(id: string) {
  return {
    type: REMOVE_QUERY_PARAMETERS_ACTION,
    id
  }
}

export function replaceAllQueryParamsAction(parameters: IKeyValueObject) {
  return {
    type: REPLACE_ALL_QUERY_PARAMETERS_ACTION,
    parameters
  }
}

/**
 * Tag parameters
 * @param parameters IKeyValueObject
 */
export interface IAddTagAction extends IAction {
  keys: string[];
  tags: IKeyValueObject[];
}


export interface IRemoveTagAction extends IAction {
  id: string;
}

type K = string[];
type T = IKeyValueObject[];
export function addTagParamsAction<T, H>(tags: T, keys: H) {
  return {
    type: ADD_TAG_PARAMETERS_ACTION,
    keys,
    tags
  };
}

export function removeTagParamsAction(id: string) {
  return {
    type: REMOVE_TAG_PARAMETERS_ACTION,
    id,
  };
}

export function replaceAllTagParamsAction(tags: T) {
  return {
    type: REPLACE_ALL_TAG_PARAMETERS_ACTION,
    tags
  }
}

/**
 * Sidebar filter parameters
 * @param parameters IKeyValueObject
 */
export interface IAddSidebarParamsActionProps {
  locations?: string[];
}

export function addSidebarParamsAction(parameters: IAddSidebarParamsActionProps) {
  return {
    type: ADD_SIDEBAR_PARAMETERS_ACTION,
    parameters
  }
}