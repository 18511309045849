import * as isEqual from 'lodash.isequal';
import { IKeyValueObject, GenericFunctionType } from "@temsa/interfaces/generic";
import { lookup, config, has, get, sanitizeNumber } from './utils';
import ArrayCollection from "../extensions/ArrayCollection";
import { win } from "./globals";
import { formatMoney, IFormatMoneyOptions } from "accounting-js";

export function setMinPrice(value: number, limit: number): number {
  return value >= limit ? value : limit;
}

export function setMaxPrice(value: number, limit: number): number {
  return value <= limit ? value : limit;
}

export function setMin(value: number, limit: number): number {
  return value >= limit ? value : limit;
}

export function setMax(value: number, limit: number): number {
  return value <= limit ? value : limit;
}

export function setFormat(value: number) {
  let strValue = value ? value.toString().replace(/[\s,.]/g, '') : '0';

  if (!/^[\d.]+$/.test(strValue)) {
    strValue = '';
  }

  const currency = win('_currency')
  const formats: string[] = config('moneyFormats')[currency];
  const moneyFormat = (new ArrayCollection(formats)).toObject(['thousand', 'decimal', 'format']);
  const a = formatMoney(
    parseInt(strValue),
    {
      symbol: '',
      precision: 0,
      ...moneyFormat
    } as IFormatMoneyOptions
    );
    return a;
}

export function rangeTag(id: string, options: IKeyValueObject = {},  params: IKeyValueObject, minKey: string, maxKey: string) {
  const merge = options.format === true
    ? () => setFormat(params[minKey])+' '+options.unit+' - '+setFormat(params[maxKey])+' '+options.unit
    : () => params[minKey]+' '+options.unit+' - '+params[maxKey]+' '+options.unit;

  return params[minKey] || params[maxKey] ? {
    id,
    name: merge(),
    filter: minKey
  } : null;
}

export function multiTag(id: string, options: IKeyValueObject, params: IKeyValueObject, key: string) {

  console.log(id, key, options, params, 'multiTag');

  if (Array.isArray(params[key])) {
    const cb = options.cb || lookup;
    return params[key].map((itemId: string) => {
      return {id: itemId, name: cb(itemId) , filter: key};
    });
  }
  return null;
}

export function singleTag(id: string, options: IKeyValueObject, params: IKeyValueObject, key: string) {
  return {id, name: (options[id] ? options[id](params[id]) : params[id]) , filter: key};
}

const gvwrUnit = config(`mapRegionToWeightUnit.${win('_region')}`);
const mileageUnit = config(`mapRegionToMileage.${win('_region')}`);
const mapKeysToCreateor = {
  'km-ranges': [rangeTag, 'km_min', 'km_max', {unit: mileageUnit, format: true}],
  'rg-ranges': [rangeTag, 'registration_date_min', 'registration_date_max', {unit: '', format: false}],
  'gvwr-ranges': [rangeTag, 'gvwr_min', 'gvwr_max', {unit: gvwrUnit, format: true}],
  'seats-ranges': [rangeTag, 'seats_min', 'seats_max', {unit: 'ad'}],
  'price-ranges': [rangeTag, 'price_min', 'price_max', {unit: win('_currency'), format: true}],
  'city': [multiTag, 'city', {}],
  'brand': [multiTag, 'brand', {}],
  'year': [singleTag, 'year', {}],
  'models': [multiTag, 'models', {}],
  'engine_power': [singleTag, 'engine_power', {}],
  'color': [singleTag, 'color', {color: (id: string) => lookup(id)}],
  'seats_layout': [multiTag, 'seats_layout', {cb: (val: string) => val}],
  'fuel_type': [singleTag, 'fuel_type', {fuel_type: (id: string) => lookup(id)}],
  'condition': [singleTag, 'condition', {condition: (id: string) => lookup(id)}],
  'gearing_type': [singleTag, 'gearing_type', {gearing_type: (id: string) => lookup(id)}],
  'euro_norm': [singleTag, 'euro_norm', {euro_norm: (id: string) => lookup(id)}],
} as IKeyValueObject;

export function generateTags(params: IKeyValueObject): IKeyValueObject[] {
  const tags = Object.keys(mapKeysToCreateor).reduce((result: IKeyValueObject[], key: string) => {
    const item = new ArrayCollection(mapKeysToCreateor[key]);

    const options = item.last();
    item.remove(item.lastIndex());

    const creator = item.first();
    item.remove(0);

    const keysValues = item.all().reduce((result, field) => {
      if (params[field]) {
        result[field] = params[field];
      }
      return result;
    }, {});
    console.log('keysValues', result, keysValues, item.all());

    if (Object.keys(keysValues).length > 0) {


      const tagResult = creator.apply(null, [key, options, keysValues, ...item.all()]);
      (!Array.isArray(tagResult) ? [tagResult] : tagResult).forEach(tag => result.push(tag));
    }
    return result;
  }, []);
  return tags.filter(Boolean);
}

export function multipleChangeHandler(parameterKey: string, collectionKey: string, selectedParametersKey: string): GenericFunctionType {
  return function (e?: any) {
    // assign parent component instances to local variables
    const self = this as React.Component;
    const props = self.props as IKeyValueObject;
    const state = self.state as IKeyValueObject;

    // merge previous state of type's with url type parameters.
    // filterTypes will have data just one time.
    const selectedItems = state[selectedParametersKey];
    let items = [...(state.params[parameterKey] || []), ...(selectedItems || [])];
    let tags = [] as IKeyValueObject[];
    const event = {...e};

    // if trigged from change event
    if (event.target) {
      items = event.target.checked ?
      [...items, event.target.value] :
      [...items.filter(id => id !== event.target.value)];
    }

    items.forEach(mid => {
      const item = props[collectionKey].find((item: IKeyValueObject) => item.id === mid) as any;
      tags.push({id: mid, name: item.name, filter: parameterKey});
    });

    self.setState({
      params: { [parameterKey]: items, tags },
      [selectedParametersKey]: [] // remove default types passed with props
    });
  }
}

export function rangeChangeHandler(minKey?: string, maxKey?: string) {
  return function(e?: any) {
    // assign parent component instances to local variables
    const self = this as React.Component;
    const props = self.props as IKeyValueObject;
    const state = self.state as IKeyValueObject;

    const event = {...e};
    const name = event.target.name;
    let value = event.target.value;

    const setter = event.target.getAttribute('data-setter');
    const setters = (this as IKeyValueObject);

    if (typeof(setters[setter]) === 'function') {
      value = setters[setter](value, state.params);
    }

    self.setState({
      params: {
        ...state.params,
        [name]: [isNaN(parseInt(value)) ? '' : setFormat(value)],
        tags: []
      }
    });
  }
}

export function multipleCloseEventHandler(parameterKey: string, collectionKey: string, selectedParametersKey: string): GenericFunctionType {
  return function() {
    // assign parent component instances to local variables
    const self = this as React.Component;
    const props = self.props as IKeyValueObject;
    const state = self.state as IKeyValueObject;
    const { params } = state;

    const checkEquality = isEqual(props.parameters[parameterKey], params[parameterKey]);

    if (!checkEquality && has(parameterKey, params) && (get(parameterKey, params) || []).length > 0) {
      const params = state.params[parameterKey] || [];
        const { tags } = this.state.params;
        setTimeout(() => {
          props.add({[parameterKey]: [...params]}, [...(tags || [])]);
        }, 20);
    }
  }
}

export function rangeCloseEventHandler(minKey: string, maxKey: string, labelId: string, options: IKeyValueObject) {
  return function() {
    // assign parent component instances to local variables
    const self = this as React.Component;
    // const props = self.props as IKeyValueObject;
    const state = self.state as IKeyValueObject;
    const { params } = state;
    const sanitizer = (val: string) => val.toString().replace(/[\s,.]/g, '');

    let min = Array.isArray(params[minKey]) ? params[minKey][0] : params[minKey];
    let max = Array.isArray(params[maxKey]) ? params[maxKey][0] : params[maxKey];

    if ((max || max === 0) && (min || min === 0)) {
      const minFormat = setFormat(min);
      const maxFormat = setFormat(max);
      self.setState({
        [minKey]: min,
        [maxKey]: max
      }, () => {
      setTimeout(() => {
        if (sanitizeNumber(min) < sanitizeNumber(max)) {
          this.props.add({
            [minKey]: sanitizer(min),
            [maxKey]: sanitizer(max)
          }, [
            {
              id: labelId,
              name: options.name ? options.name(minFormat, maxFormat) : minFormat+' '+maxFormat,
              filter: minKey
            }
          ]);
        }
      }, 20);
      })
    }
  }
}

export function equalityStrategy(minKey: string, maxKey: string) {
  return function(prevProps: IKeyValueObject = {}, props: IKeyValueObject = {}): boolean {
    const minParamsEquality = isEqual(this.props.parameters[minKey], prevProps.parameters[minKey]);
    const maxParamsEquality = isEqual(this.props.parameters[maxKey], prevProps.parameters[maxKey]);
    return minParamsEquality === false || maxParamsEquality === false;
  }
}

export function isValidMinMax(min: string, max: string): boolean {
  const minInt = sanitizeNumber(min);
  const maxInt = sanitizeNumber(max);
  return minInt > 0 && maxInt > 0 && minInt < maxInt;
}
