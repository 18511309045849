import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { flat } from '@temsa/helpers/utils';

interface ISelectedfilters {
  tags: IKeyValueObject;
  hasTemsaFeatured: boolean;
  remove: (id: string) => any;
}

export default React.memo(function SelectedFilters({tags = {}, remove, hasTemsaFeatured}: ISelectedfilters) {
  return (
    <div id="selected-filters">
      {(flat(Object.keys(tags).map((name, index: number) => tags[name]) as any)).map((tag: IKeyValueObject, tagIndex: number) => {
        const isNotFeatured = (!hasTemsaFeatured || tag.filter !== 'brand');
        return !tag.id ? null : (
          <a 
            key={tagIndex} 
            data-id={tag.id} 
            href="javascript:void(0);" 
            style={isNotFeatured ? {} : {paddingLeft: 20}}
            onClick={isNotFeatured ? () => remove(tag.id) : (() => null)}
            className="badge badge-pill badge-dark selected-filter-badge"
          >
            {isNotFeatured &&
              <svg
                className="icon icon-times"
                dangerouslySetInnerHTML={{
                  __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>'
                }}
              />
            }
            {tag.name}
          </a>
        );
      })}
    </div>
  );
});