import * as React from 'react';
import * as isEqual from 'lodash.isequal';
import { IKeyValueObject, GenericFunctionType } from '@temsa/interfaces/generic';
import { IFilterProps, ISearchParameters } from './Filter';
import { closeAllDropdownInFilter } from '@temsa/helpers/jquery';
import { win } from '@temsa/helpers/globals';

export interface IOtherFilterBarProps extends IFilterProps {
  tags: IKeyValueObject[];
  translations: IKeyValueObject;
  parameters: ISearchParameters;
  sidebarParams: IKeyValueObject;
  addParameter: (parameters: ISearchParameters, tags: IKeyValueObject) => any;
}

export default function TopFiltersHoc(
  WrappedComponent: any, 
  handler: GenericFunctionType, 
  selectedParameterKey: string,
  parameterKey: string, 
  closeEventCb?: GenericFunctionType,
  equalityStrategy?: GenericFunctionType
) {

  return class extends React.Component<any, any> {
    private handleChange: CallableFunction;
    constructor(props: any) {
      super(props);
      this.state = {
        params: {
          ...this.props.parameters
        },
        ...this.props
      };

      this.handleChange = handler.bind(this);
    }

    public componentDidUpdate(prevProps: IKeyValueObject, ownState: IKeyValueObject) {
      const key = selectedParameterKey;
      const paramsEquality = isEqual(this.props[key], prevProps[key]);
      const strategyFunc = equalityStrategy ? equalityStrategy.bind(this, prevProps, this.props) : (() => paramsEquality === false);

      if (strategyFunc()) {
        this.handleChange = handler.bind(this);
        setTimeout(() => {
          this.setState({params: {...this.props.parameters}});
        }, 10);
      }

      this.setCloseEvent();
    }

    public render() {
      return (
        <WrappedComponent 
          {...this.props} 
          state={{...this.state}}
          clear={this.clear} 
          add={this.append}
          handler={this.handleChange} 
        />
      );
    }

    private append = (params: any, tags: any) => {
      this.props.add({...params}, [...tags]);
      closeAllDropdownInFilter();
    }

    private clear = (cb: CallableFunction = () => {}) => {
      this.setState({params: {}}, () => cb());
    }

    private setCloseEvent = () => {
      const selector = `.${parameterKey}-dropdown-wrapper`;
      const externalHandler = closeEventCb ? closeEventCb.bind(this) : closeEventCb;

      win('$')(selector).off('hide.bs.dropdown');
      win('$')(selector).on('hide.bs.dropdown', (externalHandler) || (() => {
        const params = this.state.params[parameterKey] || [];
        const { tags } = this.state.params;
        setTimeout(() => {
          this.props.add({[parameterKey]: [...params]}, [...(tags || [])]);
        }, 20);
      }));
    }
  }
}