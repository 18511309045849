import * as React from "react";
import { IKeyValueObject } from "@temsa/interfaces/generic";
import { win } from "@temsa/globals";
import * as filterSetters from "resources/assets/js/helpers/filter";
import { closeAllDropdownInFilter } from "@temsa/helpers/jquery";
import TopFiltersHoc from "../TopFiltersHoc";
import { setFormat } from '../../../../helpers/filter';

export interface IPriceRangeProps {
  trans: (key: string) => string;
  add: (params: IKeyValueObject, tags: IKeyValueObject) => any;
  remove: (id: string) => any;
  handler: () => any;
  clear: (cb: CallableFunction) => any;
  state: IKeyValueObject;
  label: string;
  min: number|string;
  max: number|string;
  limit: IKeyValueObject;
}

function PriceRange({ label, min, max, add, trans, handler, clear, remove, limit, state }: IPriceRangeProps) {
  const { params } = state;
  const minPrice = !Array.isArray(params.price_min) ? (params.price_min || '') : params.price_min[0];
  const maxPrice = !Array.isArray(params.price_max) ? (params.price_max || '') : params.price_max[0];

  return (
    <div className="price-ranges-dropdown-wrapper">
      <div className="dropdown filter-dropdown">
        <button
          className="btn btn-outline-dark dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          {label}{" "}
          <svg
            className="icon icon-angle-down"
            dangerouslySetInnerHTML={{
              __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-angle-down"></use>'
            }}
          />
        </button>
        <div className="dropdown-menu">
          <form id="filter_price_params">
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="price_min"
                    className="form-control"
                    placeholder={setFormat(limit.min)}
                    min={limit.min}
                    maxLength={9}
                    data-setter="setFormat"
                    value={minPrice ? setFormat(minPrice) : ''}
                    onChange={handler}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="price_max"
                    className="form-control"
                    placeholder={setFormat(limit.max)}
                    max={limit.max}
                    data-setter="setFormat"
                    value={maxPrice ? setFormat(maxPrice) : ''}
                    maxLength={9}
                    onChange={handler}
                  />
                </div>
              </div>
            </div>
            <div className="filter-bottom">
              <button 
                type="button"  
                className="btn btn-link"
                onClick={() => clear(() => add({price_min: [], price_max: []}, []))} 
                style={{visibility: (filterSetters.isValidMinMax(minPrice, maxPrice)) ? 'visible' : 'hidden'}}>
                {trans('clear')}
              </button>
              <button 
                type="button" 
                className="btn btn-link"
                disabled={!filterSetters.isValidMinMax(minPrice, maxPrice)}
                onClick={() => closeAllDropdownInFilter()}>
                {trans('apply')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}


export default React.memo(TopFiltersHoc(
  // Stateless component.
  // it will wrap from hoc component.
  PriceRange, 

  // range inputs change handler.
  filterSetters.rangeChangeHandler(), 

  '', 

  // filter parameter name.
  'price-ranges', 

  // dropdown close event handler.
  filterSetters.rangeCloseEventHandler('price_min', 'price_max', 'price-ranges', {
    name: (min: string, max: string) => {
      return min+' '+win('_currency')+' - '+max+' '+win('_currency');
    }
  }),

  filterSetters.equalityStrategy('price_min', 'price_max')
));