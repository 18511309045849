import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import * as isEqual from 'lodash.isequal';
import container from '@temsa/reducers';
import { runIf, lookup, get, except, config, sanitizeNumber } from '@temsa/utils';
import { addComponent } from '@temsa/redux';
import CityState from './stateless/CityState';
import YearOfManufacture from './stateless/YearOfManufacture';
import EnginePower from './stateless/EnginePower';
import FuelType from './stateless/FuelType';
import GearBox from './stateless/GearBox';
import Color from './stateless/Color';
import { setMin } from 'resources/assets/js/helpers/filter';
import { addParameter, replaceParameter } from '../../actions/creators';
import { IGlobalState, IKeyValueObject } from '@temsa/interfaces/generic';
import { IFilterProps, ISearchParameters } from './Filter';
import { win } from '@temsa/globals';
import EuroNorm from './stateless/EuroNorm';
import SeatsLayout from './stateless/SeatsLayout';
import RegistrationDate from './stateless/RegistrationDate';
import GrossVehicleWeight from './stateless/GrossVehicleWeight';
import { setFormat } from '../../../helpers/filter';

export interface IOtherFilterBarProps extends IFilterProps {
  sidebarParams: IKeyValueObject;
  translations: IKeyValueObject;
  parameters: ISearchParameters;
  tags: IKeyValueObject[];
  addParameter: (parameters: ISearchParameters, tags: IKeyValueObject[]) => any;
  replaceParameter: (parameters: ISearchParameters, tags: IKeyValueObject[]) => any;
}

const filterKeys = [
  'euro_norm',
  'seats_layout',
  'fuel_type',
  'gearing_type',
  'engine_power',
  'year',
  'gvwr_max',
  'gvwr_min',
  'registration_date_max',
  'registration_date_min',
  'color'
]
class OtherFiltersBar extends React.Component<IOtherFilterBarProps, IKeyValueObject> {
  public state:Readonly<any>;
  private trans = (key: string) => key;

  constructor(props: IOtherFilterBarProps) {
    super(props);

    this.state = {
      parameters: {
        ...this.props.parameters
      } as ISearchParameters,
      tags: [
        ...this.props.tags
      ] as IKeyValueObject[]
    };
  }

  public get cities(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctLocations || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get enginePowers(): number[] {
    return this.props.sidebarParams.distinctEnginePowers;
  }

  public get fuelTypes(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctFuelTypes || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get euroNorms(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctEuroNormValues || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get isAllParamsValid(): boolean {
    return (this.hasRegistrationDate || this.hasGvwr || this.hasSelectedParameters === true)
      && (this.hasRegistrationDate ? this.isValidRegistrationDate : true)
      && (this.hasGvwr ? this.isValidGvwr : true);
  }

  public get hasSelectedParameters(): boolean {
    return this.state.tags.length > 0;
  }

  public get isValidGvwr(): boolean {
    const {gvwr_min = [], gvwr_max = []} = this.state.parameters;
    return this.hasGvwr && 
      (sanitizeNumber(gvwr_min.toString()) < sanitizeNumber(gvwr_max.toString()));
  }

  public get isValidRegistrationDate(): boolean {
    const {registration_date_min, registration_date_max} = this.state.parameters;
    const min = registration_date_min.toString();
    const max = registration_date_max.toString();

    return this.hasRegistrationDate && 
      (sanitizeNumber(min) < sanitizeNumber(max)) && 
      (min.length === 4 && max.length === 4);
  }

  public get hasRegistrationDate(): boolean {
    const {registration_date_min, registration_date_max} = this.state.parameters;
    return (!!registration_date_min && !!registration_date_max);
  }

  public get hasGvwr(): boolean {
    const {gvwr_min =  [], gvwr_max = []} = this.state.parameters;
    return (!!gvwr_min.toString() && !!gvwr_max.toString());
  }

  public get hasFuelTypes(): boolean {
    return this.props.sidebarParams.distinctFuelTypes;
  }

  public get gearingTypes(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctGearingTypes || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get hasGearingTypes(): boolean {
    return this.props.sidebarParams.distinctGearingTypes;
  }

  public get colors(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctColors || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get hasColors(): boolean {
    return this.props.sidebarParams.distinctColors;
  }

  public get conditions(): IKeyValueObject[] {
    return (this.props.sidebarParams.distinctConditions || [])
      .map((id: string) => ({id, text: lookup(id)}));
  }

  public get hasConditions(): boolean {
    return this.props.sidebarParams.distinctConditions;
  }

  public get hasEuroNorm(): boolean {
    return (this.props.sidebarParams.distinctEuroNormValues && 
      config('euroNormRegions').indexOf(win('_region')) > -1);
  }

  public get city(): string[] {
    return this.state.parameters.city || [];
  }

  public get seats() {
    const { parameters } = this.state;
    return parameters.seats_min ? {
      seats_min: [setMin(parameters.seats_min[0], this.props.sidebarParams.minMax.seats_min)],
      seats_max: [setMin(parameters.seats_max[0], this.props.sidebarParams.minMax.seats_max)]
    } : {}
  }

  public get gvwr() {
    const { parameters } = this.state;
    return parameters.gvwr_min && parameters.gvwr_max ? {
      gvwr_min: parameters.gvwr_min[0],
      gvwr_max: parameters.gvwr_max[0],
    } : {};
  }

  public get registrationDate() {
    const { parameters } = this.state;
    return parameters.registration_date_min && parameters.registration_date_max ? {
      gvwr_min: parameters.registration_date_min[0],
      gvwr_max: parameters.registration_date_max[0],
    } : {};
  }

  public get seatsTag() {
    const { parameters } = this.state;
    return (parameters.seats_max && parameters.seats_max.length > 0 ? [{
      id: 'seats-ranges', 
      name: parameters.seats_min[0]+' - '+parameters.seats_max[0], 
      filter: 'seats_min'
    }] : []);
  }

  public get gvwrTag() {
    const { parameters } = this.state;
    const unit = config(`mapRegionToWeightUnit.${win('_region')}`);
    return (!!parameters.gvwr_max && !!parameters.gvwr_min ? [{
      id: 'gvwr-ranges', 
      name: `${setFormat(parameters.gvwr_min)} ${unit} - ${setFormat(parameters.gvwr_max)} ${unit}`,
      filter: 'gvwr_min'
    }] : []);
  }

  public get registrationDateTag() {
    const { parameters } = this.state;
    return (this.hasRegistrationDate ? [{
      id: 'rg-ranges', 
      name: parameters.registration_date_min+' - '+parameters.registration_date_max, 
      filter: 'registration_date_min'
    }] : []);
  }

  public componentDidUpdate(prevProps: IFilterProps & IKeyValueObject, ownState: any) {
    const paramsEquality = isEqual(this.props.parameters, prevProps.parameters);
    const tagsEquality = isEqual(this.props.tags, prevProps.tags);

    this.trans = (key: string) => get(key, this.props.translations);

    if (paramsEquality === false || tagsEquality === false) {
      this.setState({
        parameters: {...this.props.parameters}, 
        tags: [...this.props.tags]
      }, () => console.log(this.state.parameters));
    }
  }

  public componentDidMount() {
    this.trans = (key: string) => get(key, this.props.translations);
  }

  public get rangeProps(): any {
    return get('minMax', this.props.sidebarParams);
  }

  public render() {
    return (
      <>
        <a href="#" id="mobile-filter-close-button" >
          <svg className="icon icon-times" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>'}} />
        </a>
        <div className="container">
          <div id="other-filters" >
            <form>
              <div className="other-filter-section">
                <h6 className="border-line" >{this.trans('location')}</h6>
                <CityState 
                  handler={this.changeHandler} 
                  parameters={this.state.parameters} 
                  items={this.cities} 
                />
            </div>
            {this.hasEuroNorm &&
              <EuroNorm 
                handler={this.changeHandler} 
                euroNorms={[...this.euroNorms]}
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            {!!this.props.sidebarParams.minMax &&
              <SeatsLayout 
                trans={this.trans}
                handler={this.changeHandler} 
                parameters={this.state.parameters}
                layouts={[...this.props.sidebarParams.distinctSeatLayouts]} 
              />
            }
            {this.props.sidebarParams.distinctYears && 
              <YearOfManufacture 
                handler={this.changeHandler} 
                years={[...this.props.sidebarParams.distinctYears]} 
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            {this.enginePowers && 
              <EnginePower 
                handler={this.changeHandler} 
                powers={[...this.enginePowers]} 
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            {this.hasFuelTypes && 
              <FuelType 
                handler={this.changeHandler} 
                types={[...this.fuelTypes]} 
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            {this.hasGearingTypes && 
              <GearBox 
                handler={this.changeHandler} 
                types={[...this.gearingTypes]} 
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            <GrossVehicleWeight 
              range={{...this.rangeProps}}
              min={this.state.parameters.gvwr_min || ''}
              max={this.state.parameters.gvwr_max || ''} 
              onChange={this.rangeChangeHandler}
              trans={this.trans}
            />
            <RegistrationDate 
              range={{...this.rangeProps}}
              min={this.state.parameters.registration_date_min || ''}
              max={this.state.parameters.registration_date_max || ''} 
              onChange={this.rangeChangeHandler}
              trans={this.trans}
            />
            {this.hasColors && 
              <Color 
                handler={this.changeHandler} 
                colors={[...this.colors]} 
                parameters={this.state.parameters}
                trans={this.trans}
              />
            }
            {/* {this.hasConditions && 
              <Condition 
                handler={this.changeHandler} 
                conditions={[...this.conditions]}
                parameters={this.state.parameters} 
                trans={this.trans}
              />
            } */}
          </form>
        </div>
        <div id="other-filter-action-buttons" >
          {(this.hasSelectedParameters || this.isValidGvwr) && 
            <button 
              onClick={this.clear} 
              className="btn btn-link">
              {this.trans('clear')}
            </button>
          }
          <button 
            onClick={() => win("temsa").openOtherFilters("#mobile-filter-button")} 
            className="btn btn-link">
            {this.trans('close')}
          </button>
          <button 
            disabled={!this.isAllParamsValid} 
            onClick={this.filter} 
            className="btn btn-link">
            {this.trans('apply')}
          </button>
        </div>
      </div>
      <div id="filter-closer"></div>
      </>
    );
  }

  private filter = () => {
    win('TweenLite').to('#other-filters-content', 0.2, {display: 'none', opacity: 0});
    win('$')('.other-filter-button').removeClass('btn-dark').addClass('btn-outline-dark');
    const { parameters } = this.state;
    let newParameters = { ...parameters, ...this.seats, ...this.gvwr };

    this.setState({parameters: {...newParameters}}, () => {
      const gvwrRange = parameters.gvwr_min && parameters.gvwr_max ? {
        gvwr_min: parameters.gvwr_min, 
        gvwr_max: parameters.gvwr_max
      } : {};
      const registrationDateRange = parameters.registration_date_min && parameters.registration_date_max ? {
        registration_date_min: parameters.registration_date_min, 
        registration_date_max: parameters.registration_date_max
      } : {};

      const removeParameterKeys = [
        'registration_date_min', 
        'registration_date_max', 
        'gvwr_min', 
        'gvwr_max'
      ];

      // remove previous tags via parameter keys...
      let prevTags = [...this.state.tags];
      removeParameterKeys.forEach(key => prevTags = prevTags.filter(tag => tag.filter !== key));

      this.props.addParameter({
        ...except(this.state.parameters, removeParameterKeys),
        ...gvwrRange,
        ...registrationDateRange
      }, [
        ...prevTags,
        ...this.gvwrTag,
        ...this.registrationDateTag,
        ...this.seatsTag
      ]);
    });
  };

  private clear = () => {
    this.setState({parameters: {}}, () => {
      setTimeout(() => {
        this.props.replaceParameter(except(this.props.parameters, filterKeys), [])
      }, 10)
    });
  };

  private changeHandler = (e: React.ChangeEvent<HTMLInputElement>, tags: IKeyValueObject[] = []) => {
    const { parameters } = this.state;
    let newParametes = {...parameters};
    newParametes[e.target.name] = [e.target.value];
    if (e.target.name === 'city') {
      const has = this.city.indexOf(e.target.value) > -1;
      const city = !e.target.checked && has ? this.city.filter(id => id !== e.target.value) : [...this.city, e.target.value];
      newParametes = {...newParametes, city};
    }

    tags = [...tags].filter(tag => this.state.tags.findIndex((t: IKeyValueObject) => t.id === tag.id) === -1);
    
    if (e.currentTarget.type === 'checkbox' && !e.currentTarget.checked) {
      tags = [...tags].filter(t => t.id !== e.target.value);
    }
    
    const prevTags = [...this.state.tags.filter((t: IKeyValueObject) => t.id !== e.target.value)];

    this.setState({
      parameters: {...newParametes},
      tags: [...(prevTags || []), ...(tags || [])]
    });
  };

  private rangeChangeHandler = (value: string, selector: string) => {
    const name = (document.querySelector(selector) as HTMLInputElement).getAttribute('name') || '';
    this.setState({
      parameters: {
        ...this.state.parameters, 
        [name]: [value.replace(/[^\d+]/g, '')]
      }
    });
  };
}

try {
  const mapStateToProps = (state: IGlobalState, ownProps: any) => {
    return {
      parameters: state.filterParameters,
      tags: state.tagParameters,
      sidebarParams: state.sidebarParameters,
      translations: state.filterTranslations.data,
    }
  };

  const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
      addParameter: (parameters: ISearchParameters, tags: IKeyValueObject[]) => {
        addParameter({parameters, tags})(dispatch)
      },
      replaceParameter: (parameters: ISearchParameters, tags: IKeyValueObject[]) => {
        replaceParameter(parameters, [])(dispatch)
      }
    }
  };

  const Component = connect(mapStateToProps, mapDispatchToProps)(OtherFiltersBar);
  const el = document.querySelector('[component="other-filters-bar"]');

  runIf(!!el, [
    () => {
      const otherFiltersBar = ReactDOM.render(
        <Provider store={container}>
          <Component />
        </Provider>, el
      );
      addComponent({ otherFiltersBar });
    }
  ])
} catch (e) {
  console.error(e);
}