import axios from 'axios';
import envService from './react/services/envService';

export const axiosConfig = () => {
  /* tslint:disable:no-string-literal */
  axios.defaults.baseURL = envService.get('api').baseUrl;
  // axios.defaults.headers.post['Content-Type'] = 'application/json'; // 'application/x-www-form-urlencoded';
  // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
  /* tslint:enable:no-string-literal */
}

export default function init() {
  axiosConfig();
}

// axios.interceptors.request.use(...requestInterceptor());
// axios.interceptors.response.use(...responseInterceptor());