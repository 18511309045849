import * as React from 'react';

interface IPaginationProps {
  total: number;
  active: number;
  perPage?: number;
  setPage: (e: any, page: number) => any;
}

export default function Pagination({total, active, perPage = 12, setPage}: IPaginationProps) {
  const activePage = (active: any, index: number): string => parseInt(active) === (index+1) ? 'active' : '';
  const totalPage = Math.ceil(total / perPage);
  return totalPage < 2 ? null : (
    <div className="certified-global-pagination d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {/* Previous page logic */}
          <li className="page-item previous">
            {active > 1 &&
              <a
                className="page-link" 
                aria-label="Previous"
                {...(active <= 1 ? {href: '#'} : {href: 'javascript:;', onClick: (e) => setPage(e, active - 1)})}>
                  <span aria-hidden="true">&lt;</span>
                  <span className="sr-only">Previous</span>
              </a>
            }
          </li>

          {/* Pages */}
          {Array(totalPage).fill(1).map((v, i) => (
            <li 
              key={i}
              className={
                `page-item pagination-pages ${activePage(active, i)}`
              }><a 
                  className="page-link" 
                  {...(activePage(active, i).length > 0 ? {href: '#'} : {href: 'javascript:;', onClick: (e) => setPage(e, i+1)})}>
                  {i+1}
                </a>
            </li>
          ))}

          {/* Next page logic */}
          <li className="page-item next">
            {(totalPage > 1  && active < totalPage) &&
              <a 
                aria-label="Next"
                className="page-link" 
                {...(active >= totalPage ? {href: '#'} : {href: 'javascript:;', onClick: (e) => setPage(e, active + 1)})}>
                <span aria-hidden="true">&gt;</span>
                <span className="sr-only">Next</span>
              </a>
            }
          </li>
        </ul>
      </nav>
    </div>
  );
}