import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { lookup, trans } from '@temsa/utils';

interface IFuelTypeProps {
  trans: (key: string) => string;
  types: IKeyValueObject[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: 'fuel_type', name: lookup(e.target.value), filter: 'fuel_type'}])
};

export default React.memo(function FuelType({types, parameters, handler, trans}: IFuelTypeProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('fuel-type')}</h6>
      <div className="form-group" >
        <select value={parameters.fuel_type || ''} name="fuel_type" onChange={(e) => prepare({...e}, handler)} className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {types.map(type => <option key={type.id} value={type.id}>{type.text}</option>)}
        </select>
      </div>
    </div>
  );
});