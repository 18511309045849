import * as React from "react";
import { ISearchParameters } from "../Filter";
import { IKeyValueObject, GenericFunctionType } from "@temsa/interfaces/generic";
import { closeAllDropdownInFilter } from "@temsa/helpers/jquery";
import TopFiltersHoc from "../TopFiltersHoc";
import { multipleChangeHandler, multipleCloseEventHandler } from "@temsa/helpers/filter";

export interface IModelProps {
  state: IKeyValueObject;
  models: IKeyValueObject[];
  parameters: ISearchParameters;
  handler: () => any;
  remove: (name: string) => any;
  trans: (key: string) => string;
  clear: (cb: CallableFunction) => any;
  add: (params: ISearchParameters, tags: IKeyValueObject) => any;
}

const hasModels = (params: IKeyValueObject) => !!params.models && params.models.length > 0;

const ModelComponent = React.memo(function Model({add, remove, models, trans, clear, handler, state, parameters}: IModelProps) {
  console.log('models', models);
  return (
    <div className="models-dropdown-wrapper">
      <div className="dropdown filter-dropdown">
        <button
          type="button"
          data-toggle="dropdown"
          className="btn btn-outline-dark dropdown-toggle">
          {trans('model')}{" "}
          <svg
            className="icon icon-angle-down"
            dangerouslySetInnerHTML={{
              __html:
                '<use xlink:href="/assets/images/symbol-defs.svg#icon-angle-down"></use>'
            }}
          />
        </button>
        <div className="dropdown-menu">
          <form id="filter_model_params">
            <div className="model-filter-wrapper">
              {models.map((model: IKeyValueObject, index: number) => {
                return (
                    <div key={index} className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          value={model.id}
                          onChange={handler}
                          data-name={model.name}
                          id={`customCheck-${model.id}`}
                          className="custom-control-input"
                          checked={
                            (!!state.params.models && state.params.models.indexOf(model.id) > -1)
                          }
                        />
                        <label className="custom-control-label" htmlFor={`customCheck-${model.id}`}>
                          {model.name}
                        </label>
                      </div>
                    </div>
                );
              })}
            </div>
            <div className="filter-bottom">
              <button 
                type="button" 
                className="btn btn-link"
                onClick={() => clear(() => add({models: []}, []))} 
                style={{visibility: (hasModels(state.params)) ? 'visible' : 'hidden'}}>
                {trans('clear')}
              </button>
              <button 
                type="button" 
                className="btn btn-link"
                disabled={!hasModels(state.params)}
                onClick={() => closeAllDropdownInFilter()}>
                {trans('apply')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default TopFiltersHoc(
  // Stateless component.
  // it will wrap from hoc component.
  ModelComponent, 

  // range inputs change handler.
  multipleChangeHandler('models', 'models', 'filterModels') as GenericFunctionType,

  'filterModels', 

  // filter parameter name.
  'models', 

  // dropdown close event handler.
  multipleCloseEventHandler('models', 'models', 'filterModels')
);