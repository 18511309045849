import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { trans, lookup } from '@temsa/utils';

interface IColorProps {
  trans: (key: string) => string;
  colors: IKeyValueObject[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: 'color', name: lookup(e.target.value), filter: 'color'}])
};

export default React.memo(function Color({colors, parameters, handler, trans}: IColorProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('color')}</h6>
      <div className="form-group" >
        <select value={parameters.color || ''} name="color" onChange={(e) => prepare({...e}, handler)} className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {colors.map(color => <option key={color.id} value={color.id}>{color.text}</option>)}
        </select>
      </div>
    </div>
  );
});