import * as React from 'react';
import SelectedFilters from '@temsa/components/filter/stateless/SelectedFilters';
import ListCard from '@temsa/components/filter/ListCard';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { ISearchParameters } from '@temsa/components/filter/Filter';
import Pagination from '@temsa/components/Pagination';

interface IListBodyProps {
  total: number;
  sortValue: string;
  tags: IKeyValueObject;
  items: IKeyValueObject[];
  hasTemsaFeatured: boolean;
  parameters: IKeyValueObject;
  trans: (key: string) => string;
  actions: {[prop: string]: () => any}
}

type addFunction = (params: ISearchParameters, tags: IKeyValueObject[]) => any;

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, add: addFunction) => {
  add({ 
    sort: e.target.value,
  }, []);
};

const setPage = (e: React.ChangeEvent<HTMLSelectElement>, add: addFunction, page: number) => {
  add({ page }, []);
};

export default React.memo(function ListBody({tags, parameters ,items = [], hasTemsaFeatured, total, actions, trans, sortValue = 'date_desc'}: IListBodyProps) {
  const sorts = [
    {text: trans('price-high-to-low'), key: 'price_desc'},
    {text: trans('price-low-to-high'), key: 'price_asc'},
    {text: trans('newest-arrivals'), key: 'date_desc'},
    {text: trans('previously-added'), key: 'date_asc'}
  ];
  return (
    <div className="container">
      <SelectedFilters 
        tags={{...tags}} 
        remove={actions.removeParameter} 
        hasTemsaFeatured={hasTemsaFeatured}
      />
      <div className="row-title d-block d-lg-flex">
        <h3 className="mb-lg-0 mb-4">{total} {trans('vehicle')}</h3>
        <form>
          <div className="form-group">
            <select onChange={(e) => prepare({...e}, actions.addParameter)} value={sortValue} className="form-control custom-select custom-select-sm">
              {sorts.map(sort => (<option key={sort.key} value={sort.key}>{sort.text}</option>))}
            </select>
          </div>
        </form>
      </div>
      <div className="row">
        {items.map((item: IKeyValueObject, index: number) => <ListCard trans={trans} key={index} item={item} />)}
      </div>
      <Pagination 
        total={total} 
        active={parameters.page || 1} 
        setPage={(e: any, page: number) => setPage(e, actions.addParameter, page)} 
      />
    </div>
  );
});