import * as React from "react";
import { win } from "@temsa/helpers/globals";
import TopFiltersHoc from "../TopFiltersHoc";
import { ISearchParameters } from "../Filter";
import { closeAllDropdownInFilter } from "@temsa/helpers/jquery";
import { IKeyValueObject, GenericFunctionType } from "@temsa/interfaces/generic";
import { multipleChangeHandler, multipleCloseEventHandler } from "@temsa/helpers/filter";

export interface IVehicleBrandProps {
  label: string;
  prefix: string;
  filterBrands: string[];
  disabled?: boolean;
  state: IKeyValueObject;
  brands: IKeyValueObject[];
  handler: () => any;
  remove: (name: string) => any;
  trans: (key: string) => string;
  clear: (cb: CallableFunction) => any;
  add: (params: ISearchParameters, tags: IKeyValueObject) => any;
}

const hasBrand = (params: IKeyValueObject) => !!params.brand && params.brand.length > 0;

const BrandComponent = React.memo(function Brand({ prefix, label, trans, disabled, add, remove, brands, filterBrands, clear, handler, state}: IVehicleBrandProps) {
  return (
    <div className="brand-dropdown-wrapper">
      <div onClick={win('temsa').otherFilterClose} className="dropdown filter-dropdown">
        <button
          type="button"
          disabled={disabled}
          data-toggle="dropdown"
          className="btn btn-outline-dark dropdown-toggle">
          {label}{" "}
          <svg
            className="icon icon-angle-down"
            dangerouslySetInnerHTML={{
              __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-angle-down"></use>'
            }}
          />
        </button>
        <div className="dropdown-menu">
          <form id={`${prefix}-filter_brands_params`}>
            {(brands || []).map((brand: IKeyValueObject) => {
              return (
                <div key={brand.id} className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      value={brand.id}
                      data-id={brand.id}
                      onChange={handler}
                      data-name={brand.name}
                      id={`${prefix}-customCheck-${brand.id}`}
                      className={`custom-control-input ${prefix}`}
                      checked={
                        (!!state.params.brand && state.params.brand.indexOf(brand.id) > -1)
                      }
                    />
                    <label className="custom-control-label" htmlFor={`${prefix}-customCheck-${brand.id}`}>
                      {brand.name}
                    </label>
                  </div>
                </div>
              );
            })}
            <div className="filter-bottom">
              <button 
                type="button" 
                className="btn btn-link"
                onClick={() => clear(() => add({brand: []}, []))} 
                style={{visibility: (hasBrand(state.params)) ? 'visible' : 'hidden'}}>
                {trans('clear')}
              </button>
              <button 
                type="button" 
                className="btn btn-link"
                disabled={!hasBrand(state.params)}
                onClick={() => closeAllDropdownInFilter()}>
                {trans('apply')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
})

export default TopFiltersHoc(
  // Stateless component.
  // it will wrap from hoc component.
  BrandComponent, 

  // range inputs change handler.
  multipleChangeHandler('brand', 'brands', 'filterBrands') as GenericFunctionType, 

  'filterBrands', 

  // filter parameter name.
  'brand', 

  // dropdown close event handler.
  multipleCloseEventHandler('brand', 'brands', 'filterBrands')
);