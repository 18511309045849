import * as React from 'react';
import { win } from '@temsa/helpers/globals';
import { config } from '@temsa/helpers/utils';
import { setFormat } from '../../../../helpers/filter';
import { IKeyValueObject } from '../../../../interfaces/generic';

interface IGrossVehicleWeightProps {
  trans: (key: string) => string;
  min: number;
  max: number;
  range: IKeyValueObject;
  onChange: (e: any, selector: string) => any;
}

export default React.memo(function GrossVehicleWeight({min, max, onChange, trans, range}: IGrossVehicleWeightProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('gross-vehicle-weight')} ({config('mapRegionToWeightUnit')[win('_region')]})</h6>
      <div className="form-row">
        <div className="col-md-6">
          <input 
            type="text" 
            value={!!min.toString() ? setFormat(min) : ''} 
            className="form-control gvwr_min" 
            placeholder={setFormat(range.gvwr_min)} 
            data-setter="setFormat"
            maxLength={10}
            name="gvwr_min"
            onChange={(e: any) => onChange(({...e}).target.value, '.gvwr_min')}
          />
        </div>
        <div className="col-md-6">
          <input 
            type="text" 
            value={!!max.toString() ? setFormat(max) : ''} 
            className="form-control gvwr_max" 
            placeholder={setFormat(range.gvwr_max)} 
            name="gvwr_max"
            maxLength={10}
            data-setter="setFormat"
            onChange={(e: any) => onChange(({...e}).target.value, '.gvwr_max')}
          />
        </div>
      </div>
    </div>
  );
});