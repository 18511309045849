import BaseService from "./baseService";

class AdvertisementService extends BaseService {
  protected namespace: string = 'advertisements';

  public newInstance() {
    return new AdvertisementService;
  }
}

export default new AdvertisementService;