import { collect, objectEntries, config } from '@temsa/helpers/utils';
import { win } from '@temsa/helpers/globals';

export const getTemsaId = () => {
  const hashArr = ['5d11f', '5d14706'];
  const collection = collect(objectEntries(win('_lookupTable')));
  let id: string = '', i = 0;
  
  while (!Boolean(id) && collection.length() > i) {
    const item = collect(collection.get(i, []));
    const isIdEqual = hashArr.map(hash => (item.first() || '').indexOf(hash) > -1);
    const isBrandEqual = item.all().indexOf(config('FEATURED_BRAND_NAME')) > -1;
    id = (isIdEqual.indexOf(true) > -1 && isBrandEqual) ? item.first() : id;
    i++;
  }
  return id;
};