import { ADD_TODO } from './types';
import { addQueryParamsAction, addTagParamsAction, removeQueryParamsAction, removeTagParamsAction, replaceAllQueryParamsAction, replaceAllTagParamsAction } from './filter';
import { ISearchParameters } from '@temsa/components/filter/Filter';
import { IKeyValueObject } from '@temsa/interfaces/generic';

export function addParameter({parameters, tags}: {parameters: ISearchParameters, tags: IKeyValueObject}) {
  return (dispatch: CallableFunction) => {
    dispatch(addQueryParamsAction(parameters));
    dispatch(addTagParamsAction(tags, Object.keys(parameters)));
  };
}

export function removeParameter(name: string) {
  return (dispatch: CallableFunction) => {
    dispatch(removeQueryParamsAction(name));
    dispatch(removeTagParamsAction(name));
  };
}

export function replaceParameter(parameters: ISearchParameters, tags: IKeyValueObject[]) {
  return (dispatch: CallableFunction) => {
    dispatch(replaceAllQueryParamsAction(parameters));
    dispatch(replaceAllTagParamsAction(tags));
  };
}