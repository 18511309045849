import * as React from 'react';
import { win } from '@temsa/globals';

interface IMoreFilterButtonsProps {
  trans: (key: string) => any;
  reset: any;
  label: string;
  isMobile?: boolean;
}

export default React.memo(function MoreFilterButton({reset, label, trans, isMobile = false}: IMoreFilterButtonsProps) {
  return (
    <div className="dropdown filter-dropdown">
      <button
        className="btn btn-outline-dark other-filter-button"
        id="mobile-filter-button"
        type="button"
        onClick={() => win("temsa").openOtherFilters("#mobile-filter-button")}>
        {label}{" "}
        {isMobile &&
          <svg
            className="icon icon-filter"
            dangerouslySetInnerHTML={{
              __html:
                '<use xlink:href="/assets/images/symbol-defs.svg#icon-filter"></use>'
            }}
          />
        }
      </button>
      {(typeof(reset) === 'function' && !isMobile) &&
        <button 
          onClick={reset} 
          className="btn btn-link ml-4" 
          type="button">
          {trans('remove-all') || 'TÜMÜNÜ SİL'}
        </button>
      }
    </div>
  );
});