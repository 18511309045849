import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { lookup } from '@temsa/utils';
import { ISearchParameters } from '../Filter';

export interface ICityStateProps {
  items: IKeyValueObject;
  parameters: ISearchParameters;
  handler: (e: React.ChangeEvent) => any;
}


const prepare = (e: React.ChangeEvent<HTMLInputElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: e.target.value, name: lookup(e.target.value), filter: 'city'}]);
};

export default React.memo(function CityState({items, parameters, handler}: ICityStateProps) {
  const citiesId = parameters.city || [];
  setTimeout(() => {
    items.map((item: IKeyValueObject) => {
      const input = document.getElementById(`city-state-${item.id}`) as HTMLInputElement;
      input.checked = citiesId.indexOf(item.id) > -1;
    }, 500);
  });
  return (
    <div className="filter-dropdown dropdown-menu-scroll-view-auto">
    {items.map((item: IKeyValueObject, index: number) => {
      return (
        <div key={index} className="form-group">
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              className="custom-control-input" 
              onChange={(e) => prepare({...e}, handler)} 
              value={item.id} 
              id={`city-state-${item.id}`} 
              checked={citiesId.indexOf(item.id) > -1}
              name="city"
            />
            <label className="custom-control-label" htmlFor={`city-state-${item.id}`}>{item.text}</label>
          </div>
        </div>
      );
    })}
    </div>
  );
});