import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { parse, stringify } from 'query-string';
import * as isEqual from 'lodash.isequal';
import container from '@temsa/reducers';
import { runIf, lookup, cast, config, get, collect, only } from '@temsa/utils';
import { addComponent } from '@temsa/redux';
import Model from '@temsa/components/filter/stateless/Model';
import PriceRange from '@temsa/components/filter/stateless/PriceRange';
import VehicleKm from '@temsa/components/filter/stateless/VehicleKm';
import NumberOfSeats from '@temsa/components/filter/stateless/NumberOfSeats';
import MoreFilterButton from '@temsa/components/filter/stateless/MoreFilterButton';
import ListBody from '@temsa/components/filter/stateless/ListBody';
import { IGlobalState, IKeyValueObject, IHttpResponse } from '@temsa/interfaces/generic';
import { addParameter, removeParameter, replaceParameter } from '../../actions/creators';
import init from 'resources/assets/js/initializers';
import adService from '../../services/adService';
import collectionService from '../../services/collectionService';
import FilterIndicator from './stateless/FilterIndicator';
import { IAddSidebarParamsActionProps, addSidebarParamsAction } from '../../actions/filter';
import ArrayCollection from 'resources/assets/js/extensions/ArrayCollection';
import { win } from '@temsa/globals';
import { generateTags } from 'resources/assets/js/helpers/filter';
import { addFilterTranslationsAction } from '../../actions/translations';
import { onShownFilterDropdown, onHideFilterDropdown } from '@temsa/helpers/jquery';
import Overlay from './stateless/Overlay';
import envService from '@temsa/react/services/envService';
import { modelTypeIdPattern } from '@temsa/patterns';
import { has } from '../../../helpers/utils';
import Brand from './stateless/Brand';
import { getTemsaId } from '@temsa/helpers/temsa';
export interface ISearchParameters {
  page?: number | string;
  sort?: string;
  engine_power?: string;
  year?: string;
  models?: string[];
  brand?: string[];
  euro_norm?: string[];
  color?: string;
  gearing_type?: string;
  fuel_type?: string;
  seats_layout?: string[];
  price_min?: string;
  price_max?: string;
  seats_min?: string;
  seats_max?: string;
  km_min?: string;
  km_max?: string;
  city?: string[];
  gvwr_min?: string;
  gvwr_max?: string;
  registration_date_min?: string;
  registration_date_max?: string;
}

export interface IFilterProps {
  tags?: IKeyValueObject[];
  parameters: ISearchParameters;
  addTranslations: (data: IKeyValueObject) => any;
  addParameter: (params: ISearchParameters, tags: IKeyValueObject[]) => any;
  replaceParameter: (params: ISearchParameters, tags: IKeyValueObject[]) => any;
  removeParameter: (name: string) => any;
  addSidebarParams: (parameters: IAddSidebarParamsActionProps) => any;
}

const casts = {
  price_min: 'number',
  price_max: 'number',
  seats_min: 'number',
  seats_max: 'number',
  km_min: 'number',
  km_max: 'number',
  gvwr_min: 'number',
  gvwr_max: 'number',
  registration_date_min: 'number',
  registration_date_max: 'number',
  year: 'number',
  engine_power: 'number',
  fuel_type: 'string',
  gearing_type: 'string',
  euro_norm: 'string',
  color: 'string',
  condition: 'string'
};

class Filter extends React.Component<IFilterProps | IKeyValueObject, IKeyValueObject> {
  private dataFetchTimeout: any;
  private lookupMapFunc = (id: string) => ({ id, name: lookup(id) });
  private trans = (key: string) => key;
  public state = {
    total: 0,
    loading: true,
    advertisements: [],
    hasQuery: false,
    minMax: {} as IKeyValueObject,
    distinctLocations: [] as string[],
    distinctTypes: [] as string[],
    distinctBrands: [] as string[],
    distinctModels: [] as string[],
    isDropdownOpen: false
  };

  private adService = adService.newInstance();
  private collectionService = collectionService.newInstance();

  constructor(props: IFilterProps | IKeyValueObject) {
    super(props);
    init();
    const segments = location.pathname.substr(1).split('/');
    segments.splice(1, 0, 'api');
    this.adService.setNamespace(segments.join('/'));

    segments.splice(2, 0, 'lookup');
    const collectionPath = (new ArrayCollection(segments)).removeLast().all().join('/');
    this.collectionService.setNamespace(collectionPath);
  }

  public get models(): IKeyValueObject[] {
    return this.state.distinctModels.map(this.lookupMapFunc);
  }

  public get brands(): IKeyValueObject[] {
    return this.state.distinctBrands.map(this.lookupMapFunc);
  }

  public get query() {
    return { ...parse(decodeURIComponent(location.search), { arrayFormat: 'bracket' }) };
  }

  public get hasQuery() {
    return Object.keys(this.props.parameters).length > 0;
  }

  public get featuredTemsa(): boolean {
    return get('featured', this.query, '').toUpperCase() === config('FEATURED_BRAND_NAME');
  }

  public componentDidUpdate(prevProps: IFilterProps, ownState: any) {
    const paramsEquality = isEqual(this.props.parameters, prevProps.parameters);
    const tagsEquality = isEqual(this.props.tags, prevProps.tags);

    if (paramsEquality === false || tagsEquality === false) {
      this.setState({ loading: true });
      const parameters = cast(this.props.parameters, casts);

      // clear timeout to avoid multiple requests
      clearTimeout(this.dataFetchTimeout);

      this.dataFetchTimeout = setTimeout(async () => {
        this.getData(parameters).then(this.setData).then(() => {
          const question = Object.keys(parameters).length > 0 ? '?' : '';
          const queryString = stringify(parameters, { arrayFormat: 'bracket' });
          window.history.pushState({}, '', `${location.href.split('?')[0]}${question}${queryString}`);
        }).catch(console.error);
      }, 700);
    }
    if (this.state.isDropdownOpen) {
      win('temsa').otherFilterClose();
    }
  }

  public async componentDidMount() {
    try {
      let parameters = { ...this.query };
      const translation = await this.getTranslations();
      const transData = translation.data.data.reduce((result: IKeyValueObject, t: IKeyValueObject) => {
        result[t.slug] = get(win('_language'), t.translations);
        return result;
      }, {});

      this.trans = (key: string) => get(key, transData);

      if (parameters['seats_layout']) {
        parameters['seats_layout'] = parameters['seats_layout'] ? [collect(parameters['seats_layout'] as []).first().replace(' ', '+')] : [];
      }
      if (parameters['city'] && parameters.city.length === 1 && parameters.city[0] === '') {
          delete parameters['city'];
      }
      if (parameters['price_min'] && parameters.price_min === '') {
        delete parameters['price_min'];
      }
      if (parameters['price_max'] && parameters.price_min === '') {
        delete parameters['price_max'];
      }
      if (this.featuredTemsa) {
        const temsaBrandId = getTemsaId();
        parameters = Boolean(temsaBrandId) ? { ...parameters, brand: [temsaBrandId] } : parameters;
      }

      // add filter translations to redux store
      this.props.addTranslations(transData);
      if (Object.keys(parameters).length > 0) {
        const tags = generateTags(parameters || {});
        const models = await this.filterModels(parameters, tags);
        this.props.addParameter({ ...(parameters || {}), models }, tags);
      } else {
        // clear timeout to avoid multiple requests
        clearTimeout(this.dataFetchTimeout);

        this.dataFetchTimeout = setTimeout(async () => {
          const params = await this.getData();
          this.setData(params);
        }, 1000);
      }

      onShownFilterDropdown(() => this.setState({ isDropdownOpen: true }));
      onHideFilterDropdown(() => {
        this.setState({ isDropdownOpen: false })
      });

      // replace query string
      // win('history').replaceState({}, '', location.href.split('?')[0]);
    } catch (e) {
      console.error(e);
    }
  }

  public render() {
    return (
      <>
        <div id="filters">
          {this.state.loading && <FilterIndicator />}
          {this.state.isDropdownOpen && <Overlay />}

          {/* Desktop Container */}
          <div className="container d-none d-lg-flex">
            <Brand
              prefix="d"
              trans={this.trans}
              brands={this.brands}
              label={this.trans('brand')}
              disabled={this.featuredTemsa}
              add={this.props.addParameter}
              remove={this.props.removeParameter}
              parameters={...this.props.parameters}
              filterBrands={this.props.parameters.brand || []}
            />
            <Model
              trans={this.trans}
              models={this.models}
              add={this.filterViaTypes}
              remove={this.props.removeParameter}
              parameters={...this.props.parameters}
            />
            <PriceRange
              label={this.trans('price-range')}
              trans={this.trans}
              limit={this.state.minMax}
              add={this.props.addParameter}
              remove={this.props.removeParameter}
              max={this.props.parameters.price_max || ''}
              min={this.props.parameters.price_min || ''}
              parameters={...this.props.parameters}
            />
            <VehicleKm
              add={this.props.addParameter}
              min={this.props.parameters.km_min || ''}
              max={this.props.parameters.km_max || ''}
              limit={this.state.minMax}
              trans={this.trans}
              parameters={...this.props.parameters}
            />
            <NumberOfSeats
              add={this.props.addParameter}
              min={this.props.parameters.seats_min || 0}
              max={this.props.parameters.seats_max || 0}
              limit={this.state.minMax}
              trans={this.trans}
              parameters={...this.props.parameters}
            />
            <MoreFilterButton
              {...this.props}
              trans={this.trans}
              reset={this.hasQuery ? this.reset : false}
              isMobile={false}
              label={this.trans('more-filters')}
            />
          </div>

          {/* Mobile Container */}
          <div className="container d-lg-none">
            <Brand
              prefix="m"
              label={this.trans('mobile-type')}
              trans={this.trans}
              brands={this.brands}
              add={this.props.addParameter}
              remove={this.props.removeParameter}
              parameters={...this.props.parameters}
              filterBrands={this.props.parameters.brand || []}
            />
            <PriceRange
              label={this.trans('mobile-price-range')}
              trans={this.trans}
              limit={this.state.minMax}
              add={this.props.addParameter}
              remove={this.props.removeParameter}
              parameters={...this.props.parameters}
              max={this.props.parameters.price_max || 0}
              min={this.props.parameters.price_min || 0}
            />
            <MoreFilterButton
              trans={this.trans}
              reset={this.hasQuery ? this.reset : false}
              label={this.trans('filter')}
              isMobile={true}
            />
          </div>
          {/* mobile filter cleaner button */}
          {this.hasQuery &&
            <div className="d-flex justify-content-end d-lg-none pr-md-5 pr-4 pt-3">
              <button className="btn btn-link" onClick={this.reset} type="button">{this.trans('remove-all')}</button>
            </div>
          }
        </div>
        <ListBody
          trans={this.trans}
          total={this.state.total}
          tags={{ ...this.props.tags }}
          items={this.state.advertisements}
          parameters={...this.props.parameters}
          hasTemsaFeatured={this.featuredTemsa}
          sortValue={this.props.parameters.sort}
          actions={{ addParameter: this.props.addParameter, removeParameter: this.props.removeParameter }}
        />
      </>
    );
  }

  private setData = (params: IKeyValueObject) => {
    console.log('setData', params.searchParameters);
    this.setState({
      ...params.searchParameters,
      hasQuery: Object.keys(this.query).length > 0
    },
      () => {
        this.props.addSidebarParams(params.sidebarParameters);

        // jquery plugins initialize
        win('temsa').secondHandToolTip();
      }
    );
  }

  private reset = (): void => {
    const featuredBrand = config('FEATURED_BRAND_NAME');
    const newParameters = this.featuredTemsa ? only(this.props.parameters, ['brand', 'featured']) : {};
    const newTags = this.featuredTemsa ? [this.props.tags.find((tag: IKeyValueObject) => tag.name === featuredBrand)] : [];

    // reset distinct models to add all models to state.
    this.setState({ distinctModels: [] }, () => {
      // get all data and filter parameters
      this.getData().then(params => {
        this.setState({
          ...params.searchParameters,
          hasQuery: false
        }, () => this.props.replaceParameter(newParameters, newTags.filter(Boolean)));
      });
    });
  }

  private async getData(query: IKeyValueObject = {}): Promise<IKeyValueObject> {
    const res = (await this.adService.index({ query })) as IHttpResponse;
    const distinctModels = !has('type', this.props.parameters) ?
      res.data.distinctModels :
      [...res.data.distinctModels, ...this.state.distinctModels];

    const searchParameters = {
      loading: false,
      total: res.data.total,
      minMax: res.data.minMax,
      // distinctTypes: res.data.distinctTypes,
      distinctBrands: res.data.distinctBrands,
      advertisements: res.data.searchResults,
      distinctLocations: res.data.distinctLocations,
      distinctModels,
    };

    const sidebarParameters = {
      minMax: res.data.minMax,
      distinctYears: res.data.distinctYears,
      distinctColors: res.data.distinctColors,
      distinctFuelTypes: res.data.distinctFuelTypes,
      distinctConditions: res.data.distinctConditions,
      distinctSeatLayouts: res.data.distinctSeatLayouts,
      distinctEnginePowers: res.data.distinctEnginePowers,
      distinctGearingTypes: res.data.distinctGearingTypes,
      distinctLocations: res.data.distinctLocations.filter(Boolean),
      distinctEuroNormValues: res.data.distinctEuroNormValues.filter(Boolean),
    };

    return { searchParameters, sidebarParameters };
  }

  private async getTranslations(): Promise<IHttpResponse | IKeyValueObject> {
    try {
      const transCollectionId = config('translations.advertisementListTranslations') as string;
      return this.collectionService.show(transCollectionId, { additionalUri: ['items'] });
    } catch (e) {
      console.error(e);
      return { data: { data: [] } };
    }
  }

  private filterViaTypes = async (parameters: any, tags: any) => {
    this.props.addParameter(parameters, tags);
    const modelGroupId = envService.get(`modelsOfRegion.${win('_region')}`);
    const models: IHttpResponse = await this.collectionService.show(modelGroupId, { additionalUri: ['items'] });
    const matchesModels = models.data.data.filter((model: IKeyValueObject) => parameters.models.indexOf(model._id) > -1);
    const promises = matchesModels.map((m: IKeyValueObject) => (
      this.collectionService.show(m._id, { additionalUri: ['items'] }))
    );

    const typeIdFilter = (item: IKeyValueObject) => modelTypeIdPattern.test(item.name);
    const mapTypeId = (item: IKeyValueObject) => collect(item.slug.split('-')).last();
    const types = (await Promise.all(promises)).map((res: IHttpResponse) => res.data.data[0]).filter(typeIdFilter).map(mapTypeId);
    const distinctTypes = Array.from(new Set(types));

    setTimeout(() => {
      this.setState((state) => {
        return { ...state, distinctTypes };
      });
    }, 1000);
  };

  private filterModels = async (parameters: any, tags: any): Promise<any> => {
    this.props.addParameter({ ...parameters, models: [] }, tags);
    const promises = (parameters.type || []).map((typeId: string) => {
      return this.collectionService.show(typeId);
    });

    const responses = await Promise.all(promises);
    const items = responses.map((res: IHttpResponse) => {
      const slug: string = res.data.data.slug;
      const fullSlug = `${win('_region')}-${slug}-relation-to-models`;
      const relationCollectionId = config(`mapRelationToModel.${fullSlug}`);
      return this.collectionService.show(relationCollectionId, { additionalUri: ['items'] });
    });

    return Promise.all(items).then(modelRes => {
      const models = new ArrayCollection;
      for (const res of modelRes) {
        for (const m of res.data.data) {
          models.add(m.name.match(/.*\[(.*)\]/)[1]);
        }
      }
      return models.all().filter(id => this.state.distinctModels.indexOf(id) > -1);
    }).catch(console.error);
  }
}

const mapStateToProps = (state: IGlobalState, ownProps: any) => {
  return {
    parameters: state.filterParameters,
    tags: state.tagParameters,
  }
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    addParameter: (parameters: ISearchParameters, tags: IKeyValueObject) => {
      addParameter({ parameters, tags })(dispatch)
    },
    removeParameter: (name: string) => removeParameter(name)(dispatch),
    replaceParameter: (parameters: ISearchParameters, tags: IKeyValueObject[]) => replaceParameter(parameters, tags)(dispatch),
    addSidebarParams: (parameters: IAddSidebarParamsActionProps) => dispatch(addSidebarParamsAction(parameters)),
    addTranslations: (data: IKeyValueObject) => dispatch(addFilterTranslationsAction(data))
  }
};

try {
  const Component = connect(mapStateToProps, mapDispatchToProps)(Filter);
  const el = document.querySelector('[component="filter"]');

  runIf(!!el, [
    () => {
      const filter = ReactDOM.render(
        <Provider store={container}>
          <Component />
        </Provider>, el
      );
      addComponent({ filter });
    }
  ])
} catch (e) {
  console.error(e);
}
