import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { lookup, trans } from '@temsa/utils';

interface IEuroNormProps {
  trans: (key: string) => string;
  euroNorms: IKeyValueObject[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: 'euro_norm', name: lookup(e.target.value), filter: 'euro_norm'}])
};

export default React.memo(function EuroNorm({euroNorms, parameters, handler, trans}: IEuroNormProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('euro-norm')}</h6>
      <div className="form-group" >
        <select value={parameters.euro_norm || ''} name="euro_norm" onChange={(e) => prepare({...e}, handler)} className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {euroNorms.map(norm => <option key={norm.id} value={norm.id}>{norm.text}</option>)}
        </select>
      </div>
    </div>
  );
});