import * as React from "react";
import { IKeyValueObject } from "@temsa/interfaces/generic";
import * as filterSetters from "resources/assets/js/helpers/filter";
import { closeAllDropdownInFilter } from "@temsa/helpers/jquery";
import TopFiltersHoc from "../TopFiltersHoc";
import { setFormat } from '../../../../helpers/filter';

export interface IVehicleKmProps {
  trans: (key: string) => string;
  add: (params: IKeyValueObject, tags: IKeyValueObject) => any;
  handler: () => any;
  clear: (cb: CallableFunction) => any;
  state: IKeyValueObject;
  min: string;
  max: string;
  limit: IKeyValueObject;
}

function VehicleKm({min, max, add, limit, handler, state, clear, trans}: IVehicleKmProps) {
  const { params } = state;
  const minKm = !Array.isArray(params.km_min) ? (params.km_min || '') : params.km_min[0];
  const maxKm = !Array.isArray(params.km_max) ? (params.km_max || '') : params.km_max[0];

  return (
    <div className="km-ranges-dropdown-wrapper">
      <div className="dropdown filter-dropdown">
        <button
          className="btn btn-outline-dark dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          {trans('km')}{" "}
          <svg
            className="icon icon-angle-down"
            dangerouslySetInnerHTML={{
              __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-angle-down"></use>'
            }}
          />
        </button>
        <div className="dropdown-menu">
          <form id="filter_km_params">
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="tel"
                    name="km_min"
                    maxLength={9}
                    min={limit.km_min}
                    onChange={handler}
                    className="form-control"
                    placeholder={setFormat(limit.km_min)}
                    value={minKm ? setFormat(minKm) : ''}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="tel"
                    name="km_max"
                    maxLength={9}
                    max={limit.km_max}
                    onChange={handler}
                    className="form-control"
                    placeholder={setFormat(limit.km_max)}
                    value={maxKm ? setFormat(maxKm) : ''}
                  />
                </div>
              </div>
            </div>
            <div className="filter-bottom">
              <button 
                type="button"  
                className="btn btn-link"
                onClick={() => clear(() => add({km_min: [], km_max: []}, []))} 
                style={{visibility: (filterSetters.isValidMinMax(minKm, maxKm)) ? 'visible' : 'hidden'}}>
                {trans('clear')}
              </button>
              <button 
                type="button" 
                className="btn btn-link"
                disabled={!filterSetters.isValidMinMax(minKm, maxKm)}
                onClick={() => closeAllDropdownInFilter()}>
                {trans('apply')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TopFiltersHoc(
  // Stateless component.
  // it will wrap from hoc component.
  VehicleKm, 

  // range inputs change handler.
  filterSetters.rangeChangeHandler(), 
  
  '', 

  // filter parameter name.
  'km-ranges', 

  // dropdown close event handler.
  filterSetters.rangeCloseEventHandler('km_min', 'km_max', 'km-ranges', {
    name: (min: string, max: string) => {
      return min+' KM'+' - '+max+' KM';
    }
  }),

  // equality strategy of did update hook.
  filterSetters.equalityStrategy('km_min', 'km_max')
);