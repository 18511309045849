import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';

interface IEnginePowerProps {
  trans: (key: string) => string;
  powers: number[];
  parameters: IKeyValueObject;
  handler: (e: React.ChangeEvent) => any;
}

const prepare = (e: React.ChangeEvent<HTMLSelectElement>, handler: (e: React.ChangeEvent, tags: IKeyValueObject[]) => any) => {
  handler({...e}, [{id: 'engine_power', name: e.target.value, filter: 'engine_power'}])
};

export default React.memo(function EnginePower({powers, parameters, handler, trans}: IEnginePowerProps) {
  return (
    <div className="other-filter-section" >
      <h6 className="border-line" >{trans('engine-power')}</h6>
      <div className="form-group" >
        <select value={parameters.engine_power || ''} onChange={(e) => prepare({...e}, handler)} name="engine_power" className="custom-select select-line custom-select-lg">
          <option value="">{trans('choose')}</option>
          {powers.map(power => <option key={power} value={power}>{power}</option>)}
        </select>
      </div>
    </div>
  );
});